@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
@layer base{
@font-face {
  font-family: Khyay-Regular;
  font-weight: 500;
  src: url(./assets/fonts/Khyay-Regular.ttf);
}}

@layer base{
  @font-face {
    font-family: DoHyeon-Regular;
    font-weight: 500;
    src: url(./assets/fonts/DoHyeon-Regular.ttf);
  }}
